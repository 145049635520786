import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import VegasTeam from "../images/LasVegas_Team.jpg"

const TeamVegasPage = () => (
  <>
    <SEO title="Las Vegas Location" />
    <div className="hero-section in-vegas">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
        <h3 className="is-white font-size-42 font-weight-bold">Ketamine Centers of Las Vegas</h3>
        <p className="is-white font-size-20">Meet Our Amazing Las Vegas Team</p>
      </div>
    </div>
    <div className="container is-white pt-4 pb-4">
    <h2 className="is-purple pt-4 font-weight-bold text-center">Helping You Get Better</h2>
    <p className="is-purple font-weight-bold text-center">With state of the art medical facilities where you will undergo your customized treatment in a very private, luxurious setting that is unlike any other.</p>
        <div className="row mb-4 mt-4 align-items-center">
            <div className="col-sm p-4" style={{ borderRadius: '6px' }}>
                <img style={{ width: '100%', borderRadius: '6px' }} src={VegasTeam} alt="Las Vegas Team" />
            </div>
            <div className="col-sm d-flex justify-content-center flex-column pl-4">
                <h3 className="is-purple pt-4 font-weight-bold">Safe &amp; Effective Ketamine Therapy</h3>
                <p className="is-purple pt-4">Our team brings this proven therapy to patients in a safe and comfortable outpatient setting, providing ketamine infusion therapy services using effective protocols in accordance with the highest industry standards. Our team is committed to providing the best patient outcomes to improve quality of life and well-being.</p>
                <Link className="btn btn-success my-2 my-sm-0 pl-4 pr-4 pt-3 pb-3" to="/">Schedule a Consultation</Link>
            </div>
        </div>
        <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.3879167395007!2d-115.30122668473005!3d36.084030780102395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8b88e2e0d38ef%3A0x4f3f67167e59411b!2s5741%20S%20Fort%20Apache%20Rd%20%23120%2C%20Las%20Vegas%2C%20NV%2089148!5e0!3m2!1sen!2sus!4v1576608885471!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style={{border:0, borderRadius: '6px'}} allowfullscreen=""></iframe>
    </div>
  </>
)

export default TeamVegasPage
